<template>
  <v-card
    tile
    ref="chat_container"
    class="pt-0 chat-container"
    v-bind:class="(is_open || is_chat_view) ? 'open' : 'close'"
    v-bind:elevation="(is_open || is_chat_view) ? '1' : '0'"
    v-resize="onResize"
  >
    <v-toolbar flat dense style="background-color: transparent;">
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" @click="collapse" v-show="is_open && !is_chat_view" v-bind="attrs" v-on="on">
            <v-icon>mdi-arrow-collapse-right</v-icon>
          </v-btn>
        </template>
        <span>コメント欄を閉じる</span>
      </v-tooltip>
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" @click="collapse" v-show="!is_open && !is_chat_view" style="margin-left: -6px; background-color: white;" v-bind="attrs" v-on="on">
            <v-icon>mdi-arrow-collapse-left</v-icon>
          </v-btn>
        </template>
        <span>コメント欄を開く</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" @click="auto_chat_scroll = !auto_chat_scroll" v-show="is_timeshift" v-bind="attrs" v-on="on">
            <v-icon :color="auto_chat_scroll ? 'orange' : ''">mdi-arrow-down-thick</v-icon>
          </v-btn>
        </template>
        <span>再生時間とコメントを同期する</span>
      </v-tooltip>
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" @click="change_color" v-show="!is_chat_view" v-bind:style="{color: user_color}" v-bind="attrs" v-on="on">
            <v-icon>mdi-format-color-text</v-icon>
          </v-btn>
        </template>
        <span>名前の文字色を変更</span>
      </v-tooltip>
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" @click="change_line" v-show="!is_singleline" v-bind="attrs" v-on="on">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </template>
        <span>コメントを一行表示にする</span>
      </v-tooltip>
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" @click="change_line" v-show="is_singleline" v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <span>コメントを複数行表示にする</span>
      </v-tooltip>
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" @click="open_new_chat_window" v-show="!chat_windowed && !is_chat_view" v-bind="attrs" v-on="on">
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>チャットポップアウト</span>
      </v-tooltip>
    </v-toolbar>
    
    <v-list ref="chat_list" class="overflow-y-auto chat-list" v-show="is_open || is_chat_view">
      <chat class="chat" v-for="(message, i) in messages" :key="i" :is_singleline="is_singleline" :message="message"></chat>
    </v-list>

    <v-footer absolute padless height="36" v-show="is_open || is_chat_view">
      <v-col class="pa-0">
        <input
          ref="chat_name"
          class="mx-2"
          name="name"
          :disabled="is_timeshift"
          placeholder="なまえ"
          type="text"
          style="border: gray 1px solid; width: 90%;"
          v-model="username"
          @change="set_username"
        />
      </v-col>
      <v-col class="pa-0" cols="8">
        <input
          ref="chat_text"
          class="mx-2"
          name="chat"
          :disabled="is_timeshift"
          placeholder="こめんと"
          type="text"
          style="border: gray 1px solid; width: 90%;"
          @keydown.enter="send"
        />
      </v-col>
      <v-col class="pa-0">
        <v-btn
          tile
          elevation="0"
          @click="send"
          :disabled="is_timeshift"
        >送信</v-btn>
      </v-col>
    </v-footer>
    <color-dialog
      v-bind:color_dialog="color_dialog"
      v-on:input="color_dialog = $event;get_color()"
    ></color-dialog>
  </v-card>
</template>
<script>
import Chat from "./Chat"
import ColorDialog from './ColorDialog'

import EventBus from '../plugins/EventBus'
import chat from '../plugins/chat'

export default {
  props: [ 'is_open' ],
  components: { Chat, ColorDialog },
  data() {
    return {
      is_singleline: true,
      messages: [],
      chat_windowed: false,
      username: '匿名ﾓﾌﾁｭﾝ',
      color_dialog: false,
      user_color: '#000000FF',
      popup_window: null,
      auto_chat_scroll: true,
    }
  },
  computed: {
    is_chat_view() {
      return this.$router.history.current.name === 'chat' || this.$router.history.current.name === 'timeshift-chat'
    },
    is_timeshift() {
      return this.$router.history.current.name === 'timeshift' || this.$router.history.current.name === 'timeshift-chat'
    }
  },
  methods: {
    collapse() {
      this.$emit('click_collapse')
    },
    change_line() {
      this.is_singleline = !this.is_singleline
    },
    scroll_bottom() {
      if(this.$refs.chat_list) {
        setTimeout(() => { this.$refs.chat_list.$el.scrollTop = this.$refs.chat_list.$el.scrollHeight }, 100)
      }
    },
    receive_user_list(data) {
      data
    },
    receive_message(data) {
      this.messages.push(data)
      this.scroll_bottom()
    },
    scroll_chat(seconds) {
      if(!this.auto_chat_scroll) {
        return
      }
      if(this.popup_window && !this.popup_window.closed) {
        this.popup_window.$scroll_chat(seconds)
      }
      const start = this.$router.history.current.query.start
      const to_time = start*1 + seconds*1000
      let target_index = null
      for(let i in this.messages) {
        const time = this.messages[i]['time']
        if(time < to_time) {
          target_index = i
        } else {
          break
        }
      }
      if(target_index === null) {
        return
      }
      const chats = document.getElementsByClassName('chat')
      if(chats.length <= target_index) {
        return
      }
      chats[target_index].scrollIntoView(false)
    },
    send() {
      const text = this.$refs.chat_text.value
      const name_value = this.$refs.chat_name.value
      if(text === '') {
        return
      }
      const name = name_value === '' ? '匿名ﾓﾌﾁｭﾝ' : name_value
      const date = new Date()
      const time = date.getTime()
      chat.sendMessage(name, text, time, this.user_color)
      this.$refs.chat_text.value = ''
    },
    open_new_chat_window() {
      // https://codesandbox.io/s/vue-template-r11zy?fontsize=14&file=/src/components/WindowPortal.vue:84-843
      if(this.is_timeshift) {
        this.popup_window = window.open(`/chat/ts/${this.$router.history.current.params.name}/${location.search}`, '', 'width=400,height=600')
      } else {
        this.popup_window = window.open(`/chat/${this.$router.history.current.params.name}/`, '', 'width=400,height=600')
      }
    },
    onResize() {
      if(!this.is_chat_view) {
        return
      }
      this.$refs.chat_container.$el.style.width = `${window.innerWidth}px`
    },
    get_username() {
      const saved = localStorage.getItem('username')
      if(saved !== null) {
        this.username = saved
      }
      return this.username
    },
    set_username() {
      localStorage.setItem('username', this.username)
    },
    change_color() {
      this.color_dialog = !this.color_dialog
    },
    get_color() {
      const saved = localStorage.getItem('name-color')
      if(saved !== null) {
        this.user_color = saved
      } else {
        this.user_color = '#000000FF'
      }
      return this.user_color
    }
  },
  mounted() {
    this.get_username()
    this.get_color()
    const params = this.$router.history.current.params
    const query = this.$router.history.current.query
    if(this.is_timeshift) {
      chat.connect(params.name, this.username, () => { chat.fetchTimeshiftComment(params.name, query.start, query.end) })
      EventBus.$on('receive-ts-message', this.receive_message)

      EventBus.$on('current-play-time', this.scroll_chat)
      if(this.is_chat_view) {
        window.$scroll_chat = this.scroll_chat
      }
    } else {
      chat.connect(params.name, this.username)
      EventBus.$on('receive-user-list', this.receive_user_list)
      EventBus.$on('receive-message', this.receive_message)
      EventBus.$on('on-publish', () => { this.$emit('on_publish') })
      this.scroll_bottom()
    }
  }
}
</script>

<style scoped>
.chat-list {
  height: calc(100% - 36px - 48px);
}
.open {
  width: 400px;
  height: 100%;
}
.close {
  width: 64px;
  height: 100%;
  background-color: transparent;
}

.chat-container {
  z-index: 1;
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 100%;
}
</style>
