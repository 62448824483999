<template>
  <div class="ma-2">
    <v-card v-if="!is_singleline">
      <v-card-subtitle>
        <span v-bind:style="{color: message.color}">{{ message.name }}</span> {{ this.get_full_time(message.time) }}
      </v-card-subtitle>
      <v-card-text style="color: black;">
        {{ message.message }}
      </v-card-text>
    </v-card>
    <div v-else>
      <span>
        {{ this.get_hour_min(message.time) }} <span v-bind:style="{color: message.color}">{{ message.name }}</span>: {{ message.message }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: [ 'is_singleline', 'message' ],
  methods: {
    get_hour_min(time) {
      const datetime = new Date(time)
      const hours = `${datetime.getHours()}`
      const minutes = `${datetime.getMinutes()}`
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
    },
    get_full_time(time) {
      const datetime = new Date(time)
      const months = `${datetime.getMonth()+1}`
      const date = `${datetime.getDate()}`
      const hours = `${datetime.getHours()}`
      const minutes = `${datetime.getMinutes()}`
      const seconds = `${datetime.getSeconds()}`
      return `${months.padStart(2, '0')}/${date.padStart(2, '0')} ${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
    }
  },
}
</script>
<style scoped>

</style>