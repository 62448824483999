import Vue from 'vue'
import EventBus from './EventBus'

// https://github.com/thecodeholic/deno-websocket-chat/blob/90b3690d26b359e673128d432254caea848e4366/public/client.js

const chat = {
  ws: null,
  group: null,
  name: null,
  join() {
    const event = {
      event: "join",
      groupName: this.group,
      name: this.name,
    }
    this.ws.send(JSON.stringify(event))
  },
  onMessageReceived(event) {
    event = JSON.parse(event.data)
    switch (event.event) {
      case "users":
        EventBus.$emit('receive-user-list', event.data)
        break
      case "message":
        EventBus.$emit('receive-message', event.data)
        break
      case "ts-message":
        EventBus.$emit('receive-ts-message', event.data)
        break
      case "previousMessages":
        event.data.forEach((d) => {
          EventBus.$emit('receive-message', d)
        })
        break
      case "on_publish":
        EventBus.$emit('on-publish')
        break
    }
  },
  sendMessage(name, text, time, color) {
    this.name = name
    const event = {
      event: "message",
      name: name,
      data: text,
      time: time,
      color: color,
    };
    this.ws.send(JSON.stringify(event));
  },
  fetchTimeshiftComment(name, start, end) {
    const event = {
      event: "timeshift",
      groupName: name,
      start,
      end
    }
    this.ws.send(JSON.stringify(event))
  },
  connect(group, name, func) {
    this.group = group
    this.name = name
    if (this.ws !== null) {
      return
    }
    const url = `${window.location.protocol === "https:" ? "wss" : "ws"}://${window.location.hostname}:8283/ws`
    this.ws = new WebSocket(url)
    this.ws.addEventListener("open", () => {
      this.join()
      if (typeof func === 'function') {
        func()
      }
    })
    this.ws.addEventListener("message", (e) => { this.onMessageReceived(e) })
  },
  close() {
    this.ws.close()
    this.ws = null
  }
}

Vue.use(chat)
export default chat