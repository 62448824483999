import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home'
import Live from './views/Live'
import Timeshift from './views/Timeshift'
import ChatView from './views/ChatView'
import Tutorial from './views/Tutorial'

import chat from './plugins/chat'

Vue.use(Router)

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/live/:name', name: 'live', component: Live, props: true },
  { path: '/ts/:name', name: 'timeshift', component: Timeshift, props: true },
  { path: '/chat/ts/:name', name: 'timeshift-chat', component: ChatView, props: true },
  { path: '/chat/:name', name: 'chat', component: ChatView, props: true },
  { path: '/tutorial', name: 'tutorial', component: Tutorial},
]

const router = new Router({
  routes: routes,
  mode: 'history',
  base: process.env.BASE_URL,
})

router.beforeEach((to, from, next) => {
  if (from.name === 'timeshift' || from.name === 'live') {
    chat.close()
  }
  next()
})

export default router