<template>
  <v-card elevation="2" @click="open_time_shift" class="hover-change">
    <v-card-title>{{ time_shift.name }}</v-card-title>
    <v-card-subtitle>{{ this.start_time_str }} {{ this.day_str }} ({{ size }})</v-card-subtitle>
    <v-img
      height="200"
      :src="gif_path"
      class="hover-change-gif"
    ></v-img>
    <v-img
      height="200"
      :src="jpg_path"
      class="hover-change-jpg"
    ></v-img>
  </v-card>
</template>

<script>
export default {
  props: [ 'time_shift' ],
  methods: {
    open_time_shift() {
      this.$router.push({
        path: `/ts/${this.time_shift.name}`,
        query: {
          name: this.time_shift.filename.split('.')[0],
          start: this.start_time,
          end: this.end_time,
        }
      })
    }
  },
  computed: {
    end_time() {
      const date = new Date(this.time_shift.mtime)
      return date.getTime()
    },
    start_time() {
      // 20210201072734
      // 2021 02 01 07 27 34
      const d = this.time_shift.filename.split('-')[1].split('.')[0]
      const date = new Date(
        d.substring(0, 4), // year
        d.substring(4, 6) * 1 - 1, // month
        d.substring(6, 8), // day
        d.substring(8, 10), // hour
        d.substring(10, 12), // min
        d.substring(12, 14) // sec
      )
      return date.getTime()
    },
    gif_path() {
      const name = this.time_shift.filename.split('.')[0]
      return `http://${window.location.hostname}/rec/thumb/${name}.gif`
    },
    jpg_path() {
      const name = this.time_shift.filename.split('.')[0]
      return `http://${window.location.hostname}/rec/thumb/${name}.jpg`
    },
    size() {
      const param = this.time_shift.size
      if(param > 1000000000) {
        return `${parseInt(param / 1000000000)}GB`
      } else if(param > 1000000) {
        return `${parseInt(param / 1000000)}MB`
      } else if(param > 1000) {
        return `${parseInt(param / 1000)}KB`
      }
      return `${param}B`
    },
    start_time_str() {
      const start_date = new Date(this.start_time*1)
      const year   = `${start_date.getFullYear()}`
      const month  = `${start_date.getMonth() + 1}`
      const date   = `${start_date.getDate()}`
      const hour   = `${start_date.getHours()}`
      const minute = `${start_date.getMinutes()}`
      const second = `${start_date.getSeconds()}`
      return `${year.padStart(4, '0')}/${month.padStart(2, '0')}/${date.padStart(2, '0')} ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`
    },
    day_str() {
      const date = new Date(this.start_time*1)
      const day = date.getDay()
      if(day === 1) {
        return '(月)'
      } else if(day === 2) {
        return '(火)'
      } else if(day === 3) {
        return '(水)'
      } else if(day === 4) {
        return '(木)'
      } else if(day === 5) {
        return '(金)'
      } else if(day === 6) {
        return '(土)'
      } else if(day === 0) {
        return '(日)'
      }
      return '(?)'
    }
  }
}
</script>

<style scoped>
.hover-change .hover-change-jpg {
  display: block;
}
.hover-change .hover-change-gif {
  display: none;
}

.hover-change:hover .hover-change-jpg {
  display: none !important;
}
.hover-change:hover .hover-change-gif {
  display: block !important;
}

</style>