<template>
  <v-container>
    <span class="text-h3">OBSの☆設定☆</span><br/>
    <span class="text-h5">サーバー: rtmp://133.242.132.92:8282/live</span><br/>
    <span class="text-h5">ストリームキー: 任意の英数字(部屋名になるよ)</span>
  </v-container>
</template>

<script>
export default {
  
}
</script>
<style scoped>

</style>