<template>
  <v-card elevation="2" @click="open_live" height="200">
    <v-card-title v-if="!none">{{ live.name._text }}</v-card-title>
    <v-card-title v-if="none">誰も配信していません</v-card-title>
    <v-card-text v-if="none">
      <v-img
        alt="もっふぴえん"
        class="shrink mr-2"
        contain
        :src="pien_img"
        transition="scale-transition"
        height="100"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import Pien from './Pien'

export default {
  props: [ 'none', 'live' ],
  methods: {
    open_live() {
      if(this.none) {
        this.pien()
        return
      }
      this.$router.push(`/live/${this.live.name._text}`)
    },
    pien() {
      const pien = new Pien().$mount()
      document.querySelector('#app').appendChild(pien.$el)
    }
  },
  computed: {
    pien_img() {
      const imgs = [
        require('../assets/mofupien.gif'),
        require('../assets/shima.png'),
        require('../assets/tamaro.png'),
        require('../assets/mohu-n.jpg'),
        require('../assets/made.jpg'),
        require('../assets/stea.png'),
        require('../assets/mojanbo.png'),
      ]
      const min = 0
      const max = imgs.length-1
      const num = Math.floor( Math.random() * (max + 1 - min) ) + min
      return imgs[num]
    }
  }
}
</script>

<style scoped>

</style>