<template>
  <div style="width: 100%;">
    <video-player
      :options="videoOptions"
      v-bind:class="show_chat ? 'video-chat-open' : 'video-chat-close'"
      :is_timeshift="true"
    />
    <chat-container
      :is_open="show_chat"
      v-on:click_collapse="collapse"
    />
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'

import VideoPlayer from '../components/VideoPlayer'
import ChatContainer from '../components/ChatContainer'

export default {
  components: { VideoPlayer, ChatContainer },
  computed: {
    src() {
      const name = this.$router.history.current.query.name.replaceAll('%', '%25')
      return `${window.location.protocol}//${window.location.hostname}:80/rec/${name}.mp4`
    }
  },
  data() {
    return {
      videoOptions: {
        autoplay: true,
        controls: true,
        liveui: true,
        aspectRatio: '16:9',
        sources: [{
          src: '',
          type: 'video/mp4',
        }]
      },
      show_chat: true,
    }
  },
  created() {
    this.videoOptions.sources[0].src = this.src
  },
  mounted() {
  },
  methods: {
    collapse() {
      this.show_chat = !this.show_chat
    },
  }
}
</script>

<style scoped>
.video-chat-open {
  width: calc(100% - 400px);
}
.video-chat-close {
  width: 100%;
}
</style>