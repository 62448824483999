<template>
  <v-app>
    <v-app-bar app flat dark v-if="!is_chat_view">
      <div>
        <v-btn width="auto" height="auto" elevation="0" plain :ripple="false">
          <v-img
            alt="もじゃもじゃ☆もふり～む"
            class="shrink mr-2"
            contain
            src="./assets/mofust.png"
            transition="scale-transition"
            width="300"
            @click="to_home"
          />
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn elevation="0" plain :ripple="false" @click="openAnume" class="mr-2">
        あぬめりこ
      </v-btn>
      <v-btn elevation="0" plain :ripple="false" @click="$router.push('/tutorial')">
        使い方☆
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  computed: {
    is_chat_view() {
      return this.$router.history.current.name === 'chat' || this.$router.history.current.name === 'timeshift-chat'
    }
  },

  data: () => ({
  }),

  methods: {
    to_home() {
      if(this.$router.history.current.path !== '/') {
        this.$router.push('/')
      }
    },
    openAnume() {
      window.open('/anumerico/', '_blank')
    }
  }
}
</script>

<style>
</style>
