<template>
  <div>
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';

import EventBus from '../plugins/EventBus'

export default {
  name: "VideoPlayer",
  props: [ 'options', 'is_timeshift' ],
  data() {
    return {
      player: null,
      current_play_timer: null,
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {})
    if(this.is_timeshift) {
      if(this.current_play_timer === null) {
        this.current_play_timer = setInterval(this.monitor_play_time, 1000)
      }
    }
  },
  beforeDestroy() {
    if(this.current_play_timer) {
      clearInterval(this.current_play_timer)
    }
    if (this.player) {
      this.player.dispose()
    }
  },
  computed: {
    str_options() {
      return JSON.stringify(this.options)
    }
  },
  watch: {
    str_options() {
      this.player.src({ src: this.options.sources[0].src, type: this.options.sources[0].type })
      const promise = this.player.play()
      if(promise !== undefined) {
        promise.then(_ => {
          _
          // started!
        }).catch(_ => {
          _
          document.querySelector('button.vjs-big-play-button').click()
        })
      }
    }
  },
  methods: {
    monitor_play_time() {
      EventBus.$emit('current-play-time', this.player.currentTime())
    }
  }
}
</script>