<template>
  <chat-container></chat-container>
</template>

<script>
import ChatContainer from '../components/ChatContainer'

export default {
  components: { ChatContainer },
  mounted() {
  }
}
</script>

<style scoped>

</style>