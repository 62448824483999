<template>
  <div ref="container">
    <span class="text-h1">ぴえん</span>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Pien',
  mounted() {
    const randx = Math.random() * window.innerWidth
    const randy = Math.random() * window.innerHeight
    this.$refs.container.style = `right: ${randx}px; top: ${randy}px;`
    setTimeout(() => {
      this.$refs.container.classList.add('trans')
    }, 100)
    setTimeout(() => {
      this.$refs.container.remove()
    }, 1000)
  }
})
</script>
<style scoped>
div {
  position: absolute;
  transition-property: all;
  transition-duration: 1000ms;
  transition-delay: 0s;
  transition-timing-function: ease;
  z-index: 2;
}

div.trans {
  transform: rotateZ(720deg);
  color: white;
}

</style>