<template>
  <div class="container">
    <span class="mofun text-h5" ref="mofun">ﾓﾌｰﾝ</span>
    <v-btn fab elevation="2" small class="button" @click="toggleWatched">
      <v-icon :color="is_watched ? 'green': 'grey'">{{ is_watched ? 'mdi-check' : 'mdi-eye-outline' }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ['item', 'watched'],
  data() {
    return {
      is_watched: false,
    }
  },
  mounted() {
    if(this.item['filename'] in this.watched) {
      this.is_watched = this.watched[this.item['filename']]
    }
  },
  methods: {
    toggleWatched() {
      const mofun = this.$refs.mofun
      mofun.classList.add('anim')
      setTimeout(() => {mofun.classList.remove('anim')}, 1000)

      this.$emit('toggle_watched', this.item.filename)
    }
  },
  watch: {
    watched: {
      handler() {
        this.is_watched = this.watched[this.item['filename']]
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.container {
  position: absolute;
  z-index: 10;
  right: 18px;
  top: 18px;
}
.button {
  position: absolute;
  z-index: 10;
  right: 0px;
  top: 0px;
}

@keyframes coin {
  0% {
    color: black;
    top: -16px;
  }
  100% {
    color: transparent;
    top: -120px;
  }
}
.mofun {
  position: absolute;
  z-index: 11;
  right: 0px;
  top: -16px;
  color: transparent;
  pointer-events: none;
}
.mofun.anim {
  animation-name: coin;
  animation-duration: 1s;
}
</style>