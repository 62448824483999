<template>
  <div>
    <video ref="videoPlayer" class="video-js" autoplay controls></video>
  </div>
</template>

<script>
import Hls from 'hls.js';

import EventBus from '../plugins/EventBus'

export default {
  name: "VideoPlayer",
  props: [ 'options', 'is_timeshift' ],
  data() {
    return {
      player: null,
      current_play_timer: null,
    }
  },
  mounted() {
    if(!this.options || this.options.sources.length < 1) {
      return
    }
    const video = this.$refs.videoPlayer
    const url = this.options.sources[0].src
    if(Hls.isSupported()) {
      this.player = new Hls({liveSyncDurationCount: 2})
      this.player.loadSource(url)
      this.player.attachMedia(video)
      video.play()
    } else if(video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = url
      video.play()
    }
    if(this.is_timeshift) {
      if(this.current_play_timer === null) {
        this.current_play_timer = setInterval(this.monitor_play_time, 1000)
      }
    }
  },
  beforeDestroy() {
    if(this.current_play_timer) {
      clearInterval(this.current_play_timer)
    }
    if (this.player) {
      this.player.destroy()
    }
  },
  computed: {
    str_options() {
      return JSON.stringify(this.options)
    }
  },
  watch: {
    str_options() {
      if(!this.options || !this.options.sources[0]) {
        return
      }
      const video = this.$refs.videoPlayer
      const url = this.options.sources[0].src
      if(Hls.isSupported()) {
        if(this.player === null) {
          this.player = new Hls()
        }
        this.player.loadSource(url)
        this.player.attachMedia(video)
        video.play()
      } else if(video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = url
        video.play()
      }
    }
  },
  methods: {
    monitor_play_time() {
      console.log(this.player)
      EventBus.$emit('current-play-time', 0)
    }
  }
}
</script>

<style scoped>
.video-js {
  width: 100%;
  height: 100%;
}
</style>