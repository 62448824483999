<template>
  <v-container>
    <v-row name="users">
      <span>視聴者: {{ this.watch_users_text }}</span>
    </v-row>
    <v-row>
      <span>経過時間: {{ this.living_time }}</span>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import convert from 'xml-js'

import EventBus from '../plugins/EventBus'

export default {
  props: [ 'users', 'on_live' ],
  data() {
    return {
      seconds: 0,
      live_info_timer: null,
    }
  },
  computed: {
    watch_users_text() {
      let text = ''
      this.users.forEach((username) => {
        text += `(${username})`
      })
      return text
    },
    living_time() {
      if(this.seconds < 60) {
        return `${this.seconds}秒`
      } else if(this.seconds < 3600) {
        const min = parseInt(this.seconds / 60)
        const sec = this.seconds % 60
        return `${min}分${sec}秒`
      } else if(this.seconds < 86400) {
        const hour = parseInt(this.seconds / 3600)
        const min = parseInt(this.seconds / 60) % 60
        const sec = this.seconds % 60
        return `${hour}時間${min}分${sec}秒`
      } else {
        const day = parseInt(this.seconds / 86400)
        const hour = parseInt(this.seconds / 3600) % 24
        const min = parseInt(this.seconds / 60) % 60
        const sec = this.seconds % 60
        return `${day}日${hour}時間${min}分${sec}秒`
      }
    }
  },
  mounted() {},
  methods: {
    fetch_live_info(first_time) {
      const url = `${window.location.protocol}//${window.location.hostname}:80/stats`
      axios.get(url).then((response) => {
        const xml = response.data
        const obj = convert.xml2js(xml, { compact: true })
        const streams = obj.rtmp.server.application.live.stream
        const stream_name = this.$router.history.current.params.name
        // only one live
        if(streams.name) {
          if(streams.name._text === stream_name) {
            const seconds = parseInt((streams.time._text) / 1000)
            this.seconds = seconds
            if(first_time) {
              EventBus.$emit('on-publish')
            }
          }
        } else if(streams.length > 0) {
          // multi
          const f_stream = streams.filter((v) => {
            return v.name._text === stream_name
          })
          if(f_stream.length === 0) {
            // this stream not lived
            return
          }
          const stream = f_stream[0]
          const seconds = parseInt((stream.time._text) / 1000)

          this.seconds = seconds
          if(first_time) {
            EventBus.$emit('on-publish')
          }
        }
      }).catch(_ => {
        _
        this.seconds = 0
      })
    }
  },
  beforeDestroy() {
    if(this.live_info_timer) {
      clearInterval(this.live_info_timer)
    }
    if (this.player) {
      this.player.dispose()
    }
  },
  watch: {
    on_live(b) {
      if(b) {
        if(this.live_info_timer === null) { 
          this.live_info_timer = setInterval(this.fetch_live_info, 1000)
        }
      }
    }
  }
}
</script>