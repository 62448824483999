<template>
  <div>
    <v-dialog
      v-bind:value="color_dialog"
      v-on:input="$emit('input', $event)"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          名前文字色
        </v-card-title>

        <v-color-picker
          class="ma-2"
          show-swatches
          swatches-max-height="300px"
          v-model="color"
        ></v-color-picker>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="ok"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: [ "color_dialog" ],
  data() {
    return {
      color: '#000000FF',
    }
  },
  mounted() {
    const saved = localStorage.getItem('name-color')
    if(saved !== null) {
      this.color = saved
    }
  },
  methods: {
    ok() {
      localStorage.setItem('name-color', this.color)
      this.$emit('input', false)
    }
  },
}
</script>