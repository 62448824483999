<template>
  <div style="width: 100%;">
    <video-player
      :options="videoOptions"
      v-bind:class="show_chat ? 'video-chat-open' : 'video-chat-close'"
    />
    <live-info
      class="video-chat-open"
      :users="uniq_users"
      :on_live="on_live"
    ></live-info>
    <chat-container
      :is_open="show_chat"
      v-on:click_collapse="collapse"
      v-on:on_publish="on_publish"
    />
  </div>
</template>

<script>
import axios from 'axios'
//import 'video.js/dist/video-js.css'

//import VideoPlayer from '../components/VideoPlayer'
import VideoPlayer from '../components/HlsVideoPlayer'
import ChatContainer from '../components/ChatContainer'
import LiveInfo from '../components/LiveInfo'

import EventBus from '../plugins/EventBus'

export default {
  components: { VideoPlayer, ChatContainer, LiveInfo },
  data() {
    return {
      videoOptions: {
        autoplay: true,
        controls: true,
        liveui: true,
        aspectRatio: '16:9',
        sources: []
      },
      show_chat: true,
      uniq_users: [],
      on_live: false,
    }
  },
  mounted() {
    EventBus.$on('receive-user-list', this.receive_user_list)
    this.check_live()
  },
  methods: {
    collapse() {
      this.show_chat = !this.show_chat
    },
    receive_user_list(data) {
      this.uniq_users.length = 0
      data.forEach((d) => {
        if(d.name === '匿名ﾓﾌﾁｭﾝ') {
          this.uniq_users.push(d.name)
          return true
        }
        if(this.uniq_users.indexOf(d.name) === -1) {
          this.uniq_users.push(d.name)
          return true
        }
      })
    },
    on_publish() {
      this.videoOptions.sources = [
        {
          src: `${window.location.protocol}//${window.location.hostname}:80/hls/${this.$router.history.current.params.name}.m3u8`,
          type: 'application/x-mpegURL',
        }
      ]
      this.videoOptions.autoplay = true
      this.on_live = true
    },
    check_live() {
      const url = `${window.location.protocol}//${window.location.hostname}:80/hls/${this.$router.history.current.params.name}.m3u8`
      axios.get(url).then(() => {
        this.on_publish()
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.video-chat-open {
  width: calc(100% - 400px);
}
.video-chat-close {
  width: 100%;
}
</style>