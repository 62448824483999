<template>
  <v-container mt-8>
    <div>
      <v-row>
        <span class="text-h5">配信中のモジャンボゥ</span>
      </v-row>
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <template v-if="streams === null || streams.length > 0">
          <v-col cols="12" xl="2" md="4" sm="6" v-for="(v, i) in streams" :key="i">
            <live-container :live="v"></live-container>
          </v-col>
        </template>
        <v-col cols="12" xl="2" md="4" sm="6" v-else>
          <live-container :none="true"></live-container>
        </v-col>
      </v-row>
    </div>

    <div class="mt-8">
      <v-row>
        <span class="text-h5">過去のモジャンボゥ</span>
      </v-row>
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <template v-if="time_shifts.length > 0">
          <v-col cols="12" xl="2" md="4" sm="6" v-for="(v, i) in time_shifts" :key="i" class="relative-col">
            <watched-icon
              :item="v"
              :watched="watched"
              v-on:toggle_watched="toggle_watched"
            />
            <timeshift-container :time_shift="v"></timeshift-container>
          </v-col>
        </template>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import LiveContainer from '../components/LiveContainer'
import TimeshiftContainer from '../components/TimeshiftContainer'
import WatchedIcon from '../components/WatchedIcon.vue'

import convert from 'xml-js'
import axios from 'axios'
import mock_rec from '../assets/mock_rec'

export default {
  components: { LiveContainer, TimeshiftContainer, WatchedIcon },
  data() {
    return {
      streams: null,
      time_shifts: [],
      watched: null,
      key: 'mofust-watched-filenames',
    }
  },
  mounted() {
    this.fetch_live_info()
    this.fetch_time_shift()
    
    this.setWatched()
  },
  methods: {
    fetch_live_info() {
      if(location.hostname === 'localhost') {
        const mock = `
<?xml version="1.0" encoding="utf-8" ?>
<?xml-stylesheet type="text/xsl" href="stat.xsl" ?>
<rtmp>
<nginx_version>1.15.3</nginx_version>
<nginx_rtmp_version>1.1.4</nginx_rtmp_version>
<compiler>gcc 10.2.1 20201203 (Alpine 10.2.1_pre1) </compiler>
<built>Feb  2 2021 01:10:54</built>
<pid>7</pid>
<uptime>1587709</uptime>
<naccepted>56</naccepted>
<bw_in>423920</bw_in>
<bytes_in>4129014918</bytes_in>
<bw_out>0</bw_out>
<bytes_out>4641</bytes_out>
<server>
<application>
<name>live</name>
<live>
<stream>
<name>anume</name>
<time>7573972</time><bw_in>418560</bw_in>
<bytes_in>484014918</bytes_in>
<bw_out>0</bw_out>
<bytes_out>0</bytes_out>
<bw_audio>191728</bw_audio>
<bw_video>218176</bw_video>
<client><id>2976</id><address>60.130.203.32</address><time>7584426</time><flashver>FMLE/3.0 (compatible; FMSc/1.0)</flashver><swfurl>rtmp://133.242.132.92:8282/live</swfurl><dropped>0</dropped><avsync>-8</avsync><timestamp>7573533</timestamp><publishing/><active/></client>
<meta><video><width>864</width><height>486</height><frame_rate>30</frame_rate><codec>H264</codec><profile>High</profile><compat>0</compat><level>3.1</level></video><audio><codec>AAC</codec><profile>LC</profile><channels>2</channels><sample_rate>48000</sample_rate></audio></meta>
<nclients>1</nclients>
<publishing/>
<active/>
</stream>
<nclients>1</nclients>
</live>
</application>
</server>
</rtmp>
        `
        
        const xml = mock
        const obj = convert.xml2js(xml, { compact: true })
        const streams = obj.rtmp.server.application.live.stream
        // only one live
        if(streams === undefined) {
          this.streams = []
        } else if(streams.name) {
          this.streams = [streams]
        } else if(streams.length > 0) {
          this.streams = streams
        } else {
          this.streams = []
        }
      } else {
        const url = `${window.location.protocol}//${window.location.hostname}:80/stats`
        axios.get(url).then((response) => {
          const xml = response.data
          const obj = convert.xml2js(xml, { compact: true })
          const streams = obj.rtmp.server.application.live.stream
          // only one live
          if(streams === undefined) {
            this.streams = []
          } else if(streams.name) {
            this.streams = [streams]
          } else if(streams.length > 0) {
            this.streams = streams
          } else {
            this.streams = []
          }
        })
      }
    },
    fetch_time_shift() {
      if(location.hostname === 'localhost') {
        const json = mock_rec
        if(json.length === 0) {
          return
        }

        const mp4s = json.filter((f) => {
          return f.name.substring(f.name.length - 3) === 'mp4'
        })
        if(mp4s.length === 0) {
          return
        }
        mp4s.sort((a, b) => {
          const atime = new Date(a['mtime'])
          const btime = new Date(b['mtime'])
          return btime - atime
        })

        for(let i in mp4s) {
          this.time_shifts.push({
            name: mp4s[i].name.split('-')[0],
            filename: mp4s[i].name,
            mtime: mp4s[i].mtime,
            size: mp4s[i].size,
            type: mp4s[i].type,
            time: this.time_parse(mp4s[i].mtime)
          })
        }
      } else {
        const url = `${window.location.protocol}//${window.location.hostname}:80/rec/`
        axios.get(url).then((response) => {
          const json = response.data
          if(json.length === 0) {
            return
          }

          const mp4s = json.filter((f) => {
            return f.name.substring(f.name.length - 3) === 'mp4'
          })
          if(mp4s.length === 0) {
            return
          }
          mp4s.sort((a, b) => {
            const atime = new Date(a['mtime'])
            const btime = new Date(b['mtime'])
            return btime - atime
          })

          for(let i in mp4s) {
            this.time_shifts.push({
              name: mp4s[i].name.split('-')[0],
              filename: mp4s[i].name,
              mtime: mp4s[i].mtime,
              size: mp4s[i].size,
              type: mp4s[i].type,
              time: this.time_parse(mp4s[i].mtime)
            })
          }
        })
      }
    },
    time_parse(mtime) {
      const dt = new Date(mtime)
      const year = `${dt.getFullYear()}`.padStart(4, '0')
      const months = `${dt.getMonth() + 1}`.padStart(2, '0')
      const date = `${dt.getDate()}`.padStart(2, '0')
      const hours = `${dt.getHours()}`.padStart(2, '0')
      const minutes = `${dt.getMinutes()}`.padStart(2, '0')
      const seconds = `${dt.getSeconds()}`.padStart(2, '0')
      return `${year}/${months}/${date} ${hours}:${minutes}:${seconds}`
    },
    toggle_watched(filename) {
      if(filename in this.watched) {
        this.$set(this.watched, filename, !this.watched[filename])
      } else {
        this.$set(this.watched, filename, true)
      }
      
      for(let key in this.watched) {
        if(this.time_shifts.filter(v => {return v['filename'] === key}).length === 0) {
          delete(this.watched[key])
        }
      }
      localStorage.setItem(this.key, JSON.stringify(this.watched))
    },
    setWatched() {
      const w = localStorage.getItem(this.key)
      if(w !== null) {
        this.watched = JSON.parse(w)
      } else {
        this.watched = {}
      }
    }
  }
}
</script>

<style scoped>
.relative-col {
  position: relative;
}
</style>